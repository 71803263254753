export const config = {
    ETH_CONTRACT_ADDRESS: "0xDC55a5aCcAB5cf8C6B3286920C282fe3AA6AE68F",

    

    BSC_CONTRACT_ADDRESS: "0x32ceB379Ac314A1FbFF5BE37B0875172893Cd79f",

    ETH_USDT_ADDRESS: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
    BSC_USDT_ADDRESS: "0x55d398326f99059fF775485246999027B3197955",
    RPC_ETH: "https://eth.drpc.org",
    RPC_BSC: "https://bscrpc.com",
};
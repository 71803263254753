import React, { useEffect, useState } from 'react'

export const useIsMounted = () => {

    const [mounted,setMounted] = useState(false)

    useEffect(()=>{
     setMounted(true)
    },[])
  return (
    mounted
  )
}
